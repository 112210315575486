.prefooter {
  padding: 4rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -1.125px;
  color: #ffffff;
}

.text1 {
  font-size: 16px;
  line-height: 29px;
  color: #ffffff;
  margin-top: 1.5rem;
}

.TouchBtn {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 24px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #fff;
  margin-top: 1.5rem;
}


.TouchBtn:hover {
  border: 1px solid var(--secondaryColor);
  color: var(--secondaryColor);
}

.footerForm {
  width: 100%;
  display: flex;
  align-items: center;
}

.footerForm form {
  width: 100%;
  margin-top: 1.5rem;
}
.footerForm input {
  background-color: #424040;
  height: 40px;
  border: 0;
  width: 70%;
  color: #fff;
  padding: 0 10px;
}

.footerForm input:focus {
  outline: none;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
  0 0 15px rgba(255, 255, 255, 0.4);
}

.footerForm button {
  background: var(--graidentColor);
  color: #fff;
  padding: 5px 0;
  height: 40px;
  width: calc(30% - 10px);
  border: 0;
  margin-left: 10px;
}

.footerForm button:hover{
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.3),
  0 0 30px rgba(255, 255, 255, 0.3);
}

.footer {
  background-color: #2b2b35;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 0;
}

.footerLogo {
  width: 20%;
  background-color: #3c3c47;
  height: 4.5rem;
  position: relative;
}

.footerLogo img {
  position: absolute;
  right: 25px;
  transform: translateY(50%);
}

.footerLinks {
  display: flex;
  flex-direction: column;
  width: 65%;
}
.links {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.socials{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.socials a:not(:first-child){
    margin-left: 5px !important;
}

.socialIcon:hover{
  filter: brightness(50%)
}

.links li{
  text-decoration: none;
  list-style: none;
  font-style: normal;
  font-size: 16px;
  line-height: 42px;
  color: #ffffff;
  text-decoration: none;
}

.links a{
  text-decoration: none;
}

.links li:hover{
  color: var(--secondaryColor);
}

.links a:not(:first-child){
    margin-left: 1rem;
}



.endFooter {
  height: 75px;
  display: flex;
  align-items: center;
}
.endFooter span {
  font-weight: normal;
  font-size: 14px;
  line-height: 42px;
  color: #fff;
}

.mailMessage{
  color: var(--secondaryColor);
  font-weight: 600;
  font-size: 1em;
  margin-top: 0.6em;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
  }
  .footerLogo{
      position: static;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
  }
  .footerLogo img{
      position: static;
      transform: translateY(0);

  }
}

@media screen and (max-width: 600px) {

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 3rem 0;
    }
    .footerLogo{
        position: static;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
    }
    .footerLogo img{
        position: static;
        transform: translateY(0);

    }

    .footerLinks {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 6%;
    }
  
    .links {
      justify-content: center !important;
    }
  
    .links li a{
      margin-right: 0;
      font-style: normal;
      font-size: 18px !important;
      line-height: 42px;
      color: #ffffff;
    }
  
    .endFooter {
      text-align: center;
      font-size: 12px;
    }
  }
  