h2{
    font-size: 40px !important;
}
h3{
    font-size: 30px !important;
}
p{
    font-size: 20px !important;
}
.token_content {
  background-color: #fff !important;
  padding-bottom: 25px;
}
.token_header {
  width: 100%;
  height: 70vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.token_header img {
  width: 70%;
  height: 100%;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.headerLogo {
  text-align: center;
  padding: 60px 0 40px 0;
}

.headerLogo img {
  width: 200px;
}
.headerLogo span {
  display: block;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 500;
}

/* Page1 */
.page1Card {
  width: 100%;
  position: relative;
  padding: 0 25px;
}

.page1Card::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  height: 20px;
  background-color: #2b2d3f;
}

.page1Card .cardContent {
  background-color: #0d104c;
  height: 105px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  padding: 0 35px;
  overflow: hidden;
}

.page1Card .cardContent img {
  width: 30%;
}
.page1Card .cardContent span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  padding: 0 65px;
}

.tableOfContents {
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.contentsList {
  padding: 100px 0 10% 10%;
}

.contentsList ul li {
  list-style: none;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}
.contentsList ul li:nth-child(2) {
  margin-top: 12px;
}
.contentsList .title {
  font-size: 18px;
}
.contentsList .title.bold {
  font-weight: 600;
}
.imagList {
  display: flex;
  flex-direction: column;
  position: relative;
}

.imagList img:nth-child(2) {
  width: 23%;
  margin-left: 15%;
}

.imagList img:nth-child(3) {
  width: 23%;
  align-self: flex-end;
  margin: -15% 10% 0 0;
}
/* Page1 */

/* Page2 */
.page2 {
  position: relative;
}
.page2::before {
  content: "";
  width: 100%;
  height: 20px;
  background-color: rgb(41, 41, 41);
  position: absolute;
  top: 0;
  left: 0;
}
.page2_content {
  margin-top: 50px;
}

.page2_content h2 {
  color: #3b447e;
  font-weight: 600;
}
/* Page2 */

/* Page3 */
.page3 h2{
    color: #3b447e;
    margin-top: 55px;
    font-weight: 600;
}
.p3Img{
    height: 50% !important;
    border-radius: 50px;
    margin: 25px 0;
}
/* Page3 */

.page4{
    margin-top: 15px;
    padding: 5% 0 50px 15%;
    background-color: #040521;
    width: 100%;
    color : #fff
}

.page4 h2{
    font-weight: 600;
    margin-bottom: 25px;
}
.page4_content p{
    padding-right: 25%;
}

.listItem{
    display: block;
    width: 100%;
    height: 55px;
    background : linear-gradient(90deg, rgba(255, 255, 255, 1) 25%, rgba(255, 255, 255, 0.1) 100%);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    color : #000;
    font-size: 18px;
}

.listItem.active{
    background : linear-gradient(90deg, rgba(80, 179, 214, 1) 25%, rgba(80, 179, 214, 0.1) 100%) !important;
    color: #fff;

}
/* 50b3d6 */
.listItem span:nth-child(1){
    font-weight: 600;
    width: 15%;
    text-align: center;
}

.listItem span:nth-child(2){
    font-weight: 400;
    width: 80%;
    text-align: left;
    margin-left: 15px;
}

.page5{
    padding: 75px 0;
}
.page5 h2{
    color: #3b447e;
    font-weight: 600;
}
.imgBox{
    padding: 15px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}
.page5 p{
    font-size: 18px;
}
.page5 p h3{
    color: #3b447e;
    margin-top: 55px;
    font-weight: 600;
}

.page5 .math{
    margin: 50px 0;
    padding: 25px;
    border-radius: 15px;
    font-size: 20px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}