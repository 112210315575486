
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-family: Poppins !important;
}

body {
  background-color: var(--primaryColor) !important;
  font-family: Poppins !important;
  font-style: normal;
  overflow-x: hidden !important;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.row > * {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.navbar-toggler {
  border-color: var(--secondaryColor) !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

.navbar-collapse {
  background-color: transparent !important;
}

.swiper {
  width: 93%;
  height: 100%;
  position: static !important;
}

.swiper-pagination {
    bottom: 0 !important;
}

.swiper-pagination-bullet{
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,15px)) !important;
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,15px)) !important;
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap,9px) !important;
    background-color: gray !important;

}
.swiper-pagination-bullet-active {
    background-color: var(--secondaryColor) !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
