.content {
  padding: 3rem 0;
}

.contentTitle h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 1px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 2rem;
}

.contentCards {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.cardStyle {
  width: 31%;
  background: transparent !important;
}

.dateText{
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: #fff;
}


.founderText{
    font-size: 2.2rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: rgb(173, 91, 250);
    margin-left: 1rem;
    text-align: center;
    
  }
  
  
  .cardText{
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5rem;
    color: #fff;
    margin-top: 1.5rem;
    text-align: center;
}

.comingSoon{
  text-align: center;
  color: #fff;
  border: 3px solid #fff;
  padding: 13px 20px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.3), 0 0 30px rgba(255, 255, 255, 0.4);
}

@media screen and (max-width:1200px) {
  .cardStyle {
    width: 29%;
    background: transparent !important;
  }

  .contentCards{
    padding: 0 0 50px 0;
    position: relative;
  }
  .swiperText{
    position: absolute !important;
    bottom: 2px;
    color: #fff;
  }

  .dateText{
    font-size: 1.2rem;
}


.founderText{
    font-size: 1.2rem;
  }
  
  .cardText{
    margin-top: 1rem;
    font-size: 1rem;
}

}