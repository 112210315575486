.mainContent {
  height: 400px;
  background: url(/public/img/comingsoon.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 0;
  background-color: #111049;
}

.bluring1 {
  position: absolute;
  width: 564px;
  height: 449.34px;
  left: 37%;
  top: 158.73px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(252, 97, 253, 0.28) 0%,
    rgba(255, 103, 255, 0) 100%
  );
  z-index: -1;
}
.bluring2 {
  position: absolute;
  width: 464px;
  height: 349.34px;
  left: 146px;
  top: -47.56px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(252, 97, 253, 0.28) 0%,
    rgba(255, 103, 255, 0) 100%
  );
  z-index: -1;
}
.bluring3 {
  position: absolute;
  width: 708px;
  height: 533.05px;
  right: -15em;
  top: -199.65px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(254, 146, 255, 0.28) 0%,
    rgba(252, 97, 253, 0.48) 0.01%,
    rgba(255, 103, 255, 0) 100%
  );
  z-index: -1;
}

.mailMessage {
  color: var(--secondaryColor);
  font-weight: 600;
  font-size: 1em;
  margin-top: 0.6em;
  text-align: center;
  display: block;
}

.contentItems {
  height: 600px;
  width: 50%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
  position: relative;
}

.contentItems::after {
  content: "";
  position: absolute;
  width: 2600px;
  height: 1800px;
  top: -700px;
  right: -5rem;
  border-right: 4px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(0, 0, 0, 0) 35.92%
  );
  z-index: -1;
}

/* Geçici */

.tba{
  position: relative;
  width: fit-content;
}

.tba h3 {
  font-weight: 600;
  font-size: 25.4624px;
  line-height: 27px;

  letter-spacing: 0.198925px;
  text-transform: uppercase;

  color: #ffffff;
}

.tba .playDemo {
  font-weight: 900;
  font-size: 35.5px;
  letter-spacing: 0.287728px;
  text-transform: uppercase;
  padding: 0.2rem 1rem;
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(270deg, #6300B0 25.83%, #A700F5 113.75%);
  border: 0.77027px solid #FFFFFF;
}

.tba .playDemo:hover{
  background: linear-gradient(89.87deg, #004DB0 3.02%, #00E1F5 103.21%);
  color: #fff;
}

.tba .pdfu{
  margin-top: 12px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.tba .pdfu span{
  font-weight: 300;
  font-size: 18.5963px;
  line-height: 20px;
  letter-spacing: 0.145284px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.tba .pdfu img{
 width: 32px;
 height: 30px;
}

.tba .gotoArrow{
  position: absolute;
  right: -22px;
  bottom: 12px;
}


/* Geçici */
.csContentTexts h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 45.309px;
  line-height: 48px;
  /* or 106% */

  display: flex;
  align-items: center;
  letter-spacing: 0.353976px;
  text-transform: uppercase;

  color: #ffffff;
}

.csContentTexts p {
  font-style: normal;
  font-weight: 400;
  font-size: 14.6286px;
  line-height: 24px;
  width: 85%;
  /* or 162% */
  color: #ffffff;
}

.count-down {
  display: flex;
  gap: 1.25em;
  padding: 10px 0;
}

.count-down .box {
  height: 160px;
  padding-bottom: 15px;
  background: rgba(255, 255, 255, 0.69);
  border: 0.546515px solid #ffffff;
  box-sizing: border-box;
  border-radius: 6.42894px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 2;
}

.count-down .box h3 {
  margin-top: 1em;
  font-style: normal;
  font-weight: 500;
  font-size: 40.5737px;
  line-height: 58px;

  color: #ffffff;
}

.count-down .box span {
  font-style: normal;
  font-weight: 500;
  font-size: 10.7149px;
  line-height: 16px;

  color: #ffffff;
}

.csForm {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.csForm input {
  height: 55px;
  width: 70%;
  color: rgba(0, 0, 0, 0.75);
  padding: 0 10px;
  background: #ffffff;
  box-shadow: 0px 23.8257px 51.2614px rgba(26, 38, 148, 0.25);
  border-radius: 5.77593px 0px 0px 5.77593px;
  border: 0;
}

.csForm input:focus {
  outline: none;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    0 0 15px rgba(255, 255, 255, 0.4);
}

.csForm button {
  color: #fff;
  padding: 5px 0;
  height: 55px;
  width: calc(30% - 10px);
  border: 0;
  background: linear-gradient(270deg, #6300b0 25.83%, #a700f5 113.75%);
  box-shadow: 0px 23.8257px 51.2614px rgba(26, 38, 148, 0.25);
  border-radius: 0px 5.77593px 5.77593px 0px;
  cursor: pointer;
}

.csForm button:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.3),
    0 0 30px rgba(255, 255, 255, 0.3);
}

.socials {
  margin-top: 0.75em;
}

.socials a:not(:first-child) {
  margin-left: 5px !important;
}

.socialIcon {
  filter: brightness(50%);
}
.socialIcon:hover {
  filter: brightness(100%);
}


@media screen and (max-width : 768px){
  .mainContent {
    height: 375px;
  }
  .contentItems{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .csContentTexts p{
    margin: auto;
  }
  .tba{
    margin-top: 15px;
  }
}