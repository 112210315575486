.headerTop {
  position: absolute;
  width: 100%;
  height: var(--navHeigh);
  left: 0px;
  top: 0px;
  background: transparent;
}

.navTop {
  width: 100%;
  height: 80px;
  padding: 0 30px;
  background: linear-gradient(0deg, #1d1d27, #1d1d27), rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(30px);
}

.navTopTrs {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 0 30px;
  background: rgba(255, 255, 255, 0.11);
  backdrop-filter: blur(30px) !important;
  z-index: 999;

}



@media screen and (max-width: 900px) {
  .navTop {
    width: 100%;
    height: auto !important;
    background: linear-gradient(0deg, #1d1d27, #1d1d27),
      rgba(255, 255, 255, 0.12);
  }

  .navTopTrs {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: rgba(255, 255, 255, 0.085);
    backdrop-filter: blur(30px) !important;
    z-index: 999;
  }

  .navLink {
    margin-left: 0 !important;
    position: static !important;
    font-weight: 500;
    font-size: 18px !important;
    border-bottom: 1px solid #545454;
  }
  .navCollapse{
    margin-bottom: 20px;
  }
  
}
.navLink {
  margin-left: 5px !important;
  position: relative;
  top: 10px !important;
  left: 0;
  font-weight: 500;
  font-size: 17px;
  color: #ffffff !important;
}

.navLink:last-child {
  margin-bottom: 10px;
}

.navLink:hover {
  color: #6300b0 !important;
}

.btnLp {
  margin-left: 7px;
  background: linear-gradient(270deg, #6300b0 25.83%, #a700f5 113.75%);
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px;
  padding: 8px 25px !important;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.75px;
  color: #fff !important;
}
.btnLp:hover {
  border: 1px solid var(--secondaryColor);
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}

.btnGs {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.75px;
  padding: 8px 25px !important;

  border: 1px solid #ffffff !important;
  box-sizing: border-box;
  color: #f7f7fc !important;
}

.btnGs:hover {
  border: 1px solid #6300b0 !important;
  color: #6300b0 !important;
}
