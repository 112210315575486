.header {
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  position: relative;
}

.header .title {
  position: relative;
  z-index: 2 !important;
  width: 34%;
}

.header .title h2 {
  font-weight: bold;
  font-size: 64px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.header .desc {
  margin-top: 30px;
  width: 34%;
  position: relative;
  z-index: 2 !important;
}

.header .desc p {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  text-align: left;
  color: #ffffff;
}

.blackfilter {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.35);
  position: absolute;
  z-index: 0;
}

.cs-content {
  width: 100;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/public/img/contentbg.jpg);
  position: relative;
}

.cs-content .text h1 {
  text-align: center;
  color: #fff;
  border: 3px solid #fff;
  padding: 13px 20px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.3), 0 0 30px rgba(255, 255, 255, 0.4);
}

.blackfilter2 {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.72);
  filter: contrast(100%);
  position: absolute;
  z-index: 0;
}

@media screen and (max-width: 1368px) {
  .header .title {
    width: 40%;
  }
  
  .header .desc {
    width: 40%;
  }
}

@media screen and (max-width: 1000px) {
  .header .title {
    width: 63%;
  }
  
  .header .desc {
    width: 63%;
  }
}

@media screen and (max-width: 600px) {
  .header {
    background-position: right !important;
    height: 100vh;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    text-align: center;
  }
  

  .header .title h2 {
    font-size: 42px;
  }

  .header .title {
    width: 100%;
  }

  .header .desc {
    margin-top: 40px;
    width: 100%;
  }

  .header .desc p {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 28px;
    color: #ffffff;
    text-align: center;
  }
}
