
.gamesSection {
  margin-top: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gamesTitle {
  font-weight: bold;
  font-size: 26px;
  line-height: 39px;
  position: relative;
  display: inline;
  letter-spacing: 1px;

  color: #ffffff;
}

.gamesTitle::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--secondaryColor);
  bottom: -8px;
  left: 0;
}

.gamesSubTitle {
  font-weight: 600;
  font-size: 40px;
  /* line-height: 34px; */
  letter-spacing: -1.125px;
  color: #ffffff;
  display: block;
}
.gamesSubTitle2 {
  font-weight: 600;
  font-size: 40px;
  /* line-height: 34px; */
  letter-spacing: -1.125px;
  color: #9b0adf;
  display: block;
}

.gamesDesc {
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
}

.g__btn {
  border: 1px solid #ffffff;
  background-color: transparent;
  border-radius: 5px;
  color: #f7f7fc;
  padding: 10px 35px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.75px;
  text-decoration: none;

}

.g__btn:hover {
  color: #fff;
  border: 1px solid #fff;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.3);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}

.itemBox,.itemBox2 {
  position: relative;
}

.itemBox svg {
  position: absolute;
  width: 55px;
  height: 55px;;
  left: 50%;
  top: 45%;
  transform: translate(-50%);
  cursor: pointer;
}

.itemBox2 svg {
  position: absolute;
  width: 40px;
  height: 40px;;
  left: 50%;
  top: 40%;
  transform: translate(-50%);
  cursor: pointer;
}

.itemBox svg:hover path,
.itemBox2 svg:hover path  
{
  fill: var(--secondaryColor);
}

@media screen and (max-width: 1180px) {
  .gamesSection {
    padding: 0 5px;
  }

  .gamesTitle {
    font-size: 30px;
    width: 100%;
    line-height: 28px;
    display: inline-block;
    text-align: center !important;
  }
  .gamesSubTitle {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    letter-spacing: -0.5px;
    display: block !important;
    text-align: center;
  }

  .gamesDesc {
    font-weight: normal;
    font-size: 18px;
    line-height: 29px;
    color: #ffffff;
    display: block;
    text-align: center !important;
  }

  .g__texts {
    margin-top: 25px !important;
  }

  .g__submit {
    text-align: center;
  }

  .itemBox svg {
  width: 40px;
  height: 40px;;
}

.itemBox2 svg {
  width: 23px;
  height: 23px;;
}
}

