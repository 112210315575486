.About .cardBody {
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 50px;
  gap: 50px;
  min-height: 450px;
  width: 100%;
}

.About .cardBody.reverse {
  flex-direction: row-reverse;
}

.About .buildContents {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 35%;
}
.About .cardImg {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About .cardImg img {
  width: 95%;
  max-width: 450px;
  max-height: 450px;
}

.About .buildName {
  font-weight: 700;
  font-size: 24px;
  width: 100%;
  letter-spacing: 1px;
  color: #ffffff;
  position: relative;
  display: inline;
  width: min-content;
}

.About .buildName::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  left: 0;
  bottom: -3px;
}

.About .buildTitle {
  font-weight: 700;
  font-size: 2.5em;
  line-height: 52px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.About .buildTexts {
  font-weight: 600;
  font-size: 1em;
  line-height: 28px;
  color: #ffffff;
}

.About .buildBtn {
  font-size: 1em;
  background: transparent;
  border: 1px solid #f7f7fc;
  padding: 4px 14px;
  color: #f7f7fc;
  width: 170px;
  margin-top: 1.5em;
}

.About .buildBtn:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 40px rgba(255, 255, 255, 0.3);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}

.About .cardItems {
  display: flex;
  justify-content: center;
  gap: 2em;
  padding: 0 2.5em;
  margin: 2em 0 !important;
}

.About .cardItem {
  height: 500px;
  background: #2b2b35 !important;
  color: #fff;
  padding: 1em 0;
  width: 33%;
}

.About .card-body {
  flex: 0 !important;
}

.About .card-img-top {
  width: 220px;
  border-radius: 50%;
  height: 200px;
  margin: auto;
}

.About .card-title {
  text-align: center;
  font-weight: 600;
  font-size: 1.75em !important;
  line-height: 44px;
  letter-spacing: -1.125px;
  text-transform: capitalize;
  color: #ffffff;
}

.About .card-text {
  width: 85%;
  margin: auto;
  margin-top: 1em;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  height: 3.5em;
}

.About .cardBtn {
  margin: auto;
  padding: 4px 0;
  width: 75%;
  background: linear-gradient(270deg, #6300b0 25.83%, #a700f5 113.75%);
  border: 0;
  color: #f7f7fc;
  font-size: 1em;
  font-weight: 600;
}

.About .cardBtn:hover {
  color: #fff;
  box-shadow: inset 0 0 20px rgba(58, 58, 58, 0.5),
    0 0 40px rgba(58, 58, 58, 0.3);
}

.About .teamTitle{
  display: block;
  text-align: center;
  color: #fff;
  margin: 2em 0 1em 0;
  font-size: 2.2em;
  position: relative;
}

.About .teamTitle::after{
  content: "";
  width: 80px;
  height: 3px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -10px;
  background-color: #fff;
}


.About .teams {
  margin: 1em 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.About .teams .members {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(100% / 4 - 15px);
  gap: 1em;
  margin-top: 2em;
  color: #fff;
}
.About .teams .members img {
  width: 185px;
  height: 185px;
  border-radius: 50%;
}

.About .teams .members .memberInfo {
  text-align: center;
}

.About .teams .members .title {
  font-weight: 700;
  font-size: 1.2em;
  line-height: 27px;
  color: #ffffff;
}

.About .roadMap {
  margin: 3em 0;
  display: flex;
  width: 100vw;
  height: 250px;
  gap: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(196, 196, 196, 0.14);
}

.About .roadMap .title {
  font-weight: 700;
  font-size: 3em;
  line-height: 52px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #ffffff;
}

.About .roadMap .text {
  font-style: normal;
  font-weight: 600;
  font-size: 1.1em;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.About .roadMap .text a {
  text-decoration: none;
}

.About .roadMap .text a:hover {
  color: #00e1f5;
}

.About .partners {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3em;
  margin-bottom: 2em;
}

.About .partners .title {
  font-weight: 700;
  font-size: 2.5em;
  line-height: 52px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #ffffff;
}

.About .partners .partnerItems {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 4em;
  flex-wrap: wrap;
}
.About .partners .partnerItems .item {
  width: calc(100% / 4 - 4em);
}

.About .partners .partnerItems img {
  width: 140px;
}

@media screen and (max-width: 1024px) {
  .About .buildTitle {
    font-size: 1.4em;
    line-height: 1.5em;
  }
  .About .buildTexts {
    font-size: 0.9em;
  }

  .About .cardItem {
    height: 400px;
    background: #2b2b35 !important;
    color: #fff;
    padding: 1em 0;
    width: 33%;
  }

  .About .cardItems {
    display: flex;
    justify-content: center;
    gap: 0.5em;
    padding: 0;
    margin: 1.5em 0 !important;
  }

  .About .card-img-top {
    width: 150px;
    height: 140px;
  }

  .About .card-title {
    font-weight: 600;
    font-size: 1.5em !important;
    letter-spacing: -1.125px;
    text-transform: capitalize;
    color: #ffffff;
  }

  .About .card-text {
    width: 85%;
    margin: auto;
    margin-top: 1em;
    font-size: 0.75em;
    line-height: 16px;
    height: 1.5em;
  }

  .About .teams .members {
    flex-direction: column;
    width: calc(100% / 3 - 15px);
  }

  .About .partners .partnerItems .item {
    width: calc(100% / 3 - 4em);
  }

  .About .partners .partnerItems img {
    width: 140px;
  }
}

.About .swiper-slide img {
  width: 75% !important;
}

.About .swiper-wrapper {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 764px) {
  .About .cardBody {
    flex-direction: column;
    padding: 15px;
  }

  .About .cardItems {
    flex-direction: column;
  }

  .About .cardItem {
    height: 550px !important;
    width: 95%;
    height: 500px;
    margin: auto;
  }

  .About .card-title {
    font-weight: 600;
    font-size: 2em !important;
    letter-spacing: -1.125px;
    text-transform: capitalize;
    color: #ffffff;
  }

  .About .card-text {
    width: 90%;
    margin: auto;
    margin-top: 1em;
    font-size: 1em;
    line-height: 26px;
    height: 3em;
  }

  .About .buildTitle {
    font-size: 1.85em;
    line-height: 1.5em;
  }

  .About .buildTexts {
    font-size: 0.95em;
  }

    .About .card-img-top {
    width: 250px;
    height: 240px;
  }

  .About .cardBody.reverse {
    flex-direction: column;
  }
  .About .buildContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    text-align: center;
  }
  .About .cardImg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .About .teams .members img {
    width: 145px;
    height: 145px;
    border-radius: 50%;
  }

  .About .teams .members {
    flex-direction: column;
    width: calc(100% / 2 - 15px);
  }

  .About .teams .members img {
    width: 9em;
  }
}
