.header {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  width: 100%;
  padding: 3rem;
}

.header_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(var(--navHeigh) - 10px);
}

.header_content .logo img {
  width: 225px;
  margin-bottom: 14px;
}

.header_content .text h3 {
  font-size: 28px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.75);
  letter-spacing: 1px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1.5rem;
}
.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.5rem 1rem 1.5rem 1rem;
  width: 21rem;
  flex-direction: column;
  background-color: rgb(255, 255, 255, 0.75);
  border-radius: 0px;
  cursor: pointer;
}

.cards:hover {
  background-color: rgb(99, 0, 176, 0.85);
  box-shadow: rgba(99, 0, 176, 0.76) 0px 20px 100px 10px;
}

.cardTitle h3 {
  background: var(--graidentColor);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cardTitle span{
  display: block;
  text-align: center;
  color: #3882e6;
}

.cards:hover > .cardTitle h3 {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cards:hover > .cardTitle span {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cards:hover > .cardText p {
  color: #fff;
}

.cardText {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.cardText p {
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  color: #000;
  width: 85%;
}

.cardImage {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}

.cardImage img{
  width: 200px;
}

.Accordion {
  background-color: #f3f5f6;
  padding: 3rem 0;
}

.Accordion .title {
  text-align: center;
  margin-bottom: 2rem;
}

.Accordion .title h3 {
  text-align: center;
  font-weight: 600;
  font-size: 44px;
  line-height: 44px;
  letter-spacing: -1.125px;
  color: #000;
}

.headerContent {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
}

.headerContent span {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 32px;
  margin: 0px 18px;
  text-align: center;
  color: rgb(60, 60, 67, 0.5);
}

.bodyText {
  padding: 16px 12px;
  font-size: 19px;
}

.accordion-button:not(.collapsed) {
  color: var(--secondaryColor) !important;
  background-color: rgb(99, 0, 176, 0.45) !important;
  box-shadow: inset 0 -1px 0 rgb(99 0 176 / 13%) !important;
}

@media screen and (max-width: 600px) {
  .header {
    padding: 2.2rem 0.5rem;
  }

  .header_content .logo img {
    width: 175px;
    margin-bottom: 16px;
  }

  .header_content .text h3 {
    font-size: 22px;
    line-height: 20px;
  }

  .content {
    flex-direction: column;
    gap: 0.5rem !important;
    margin-top: 0.5rem;
  }

  .Accordion .title h3 {
    font-size: 20px;
    line-height: 44px;
    letter-spacing: -1.125px;
    color: #000;
  }

  .cardText {
    margin-top: 0.5rem;
  }
  .cardText p {
    font-size: 12px;
    line-height: 20px;
    width: 100% !important;
  }
  .cards {
    width: calc(100vw - 20px);
    max-height: 240px;
    padding: 1rem !important;
  }

  .cardTitle {
    text-align: center;
  }

  .cardImage {
    margin-top: 0.5rem;
  }

  .cardImage img {
    width: 100px;
  }

  .headerContent {
    font-weight: 600;
    font-size: 16px;
    padding: 0 5px 0 0;
  }

  .headerContent span {
    min-height: 40px !important;
    font-size: 24px;
    margin: 0 10px;
  }

  .bodyText {
    padding: 16px 2px;
    font-size: 14px;
  }
}
