.buildSection {
  margin-top: 25px;
  overflow-x: clip;
}

.sectionContainer {
  background: linear-gradient(270deg, #1c0131 25.83%, #a700f5 113.75%);
  width: 100%;
  position: relative;
  padding: 5em 0;
}

.sectionContent {
  padding: 100px 60px;
  width: 50%;
}

.sectionTitle h2 {
  position: relative;
  display: inline-block;
  font-weight: bold;
  font-size: 28px;
  line-height: 39px;
  color: #ffffff;
  margin-bottom: 50px;
}

.sectionTitle h2::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #fff;
}

.SectionTexts h3 {
  font-weight: bold;
  font-size: 50px;
  line-height: 44px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.SectionTexts p {
  margin-top: 35px;
  font-weight: normal;
  font-size: 17px;
  line-height: 28px;
  color: #ffffff;
}

.sectionBtn {
  margin-top: 35px;
}
.sectionBtn a {
  padding: 5px 25px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  font-weight: 600;
  text-decoration: none;
}

.sectionBtn a:hover {
  color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 0 20px rgba(58, 58, 58, 0.5), 0 0 40px rgba(58, 58, 58, 0.3);

}

.contentImg img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -220px;
  z-index: 0;
  
}


@media screen and (max-width: 1024px) {
  
  .sectionContent {
    width: 100%;
    text-align: center;
    padding : 2rem 0 !important;
  }
  
  .SectionTexts{
    padding: 0 1em;
  }
  .contentImg{
    position: static;
    z-index: 1;
    margin: 3.5em 0 0 0;
  }
  .contentImg img {
    position: static;
    width: 70%;
    height: 60%;
    transform: scale(1.6);
    margin-left: 6em;
    margin-top: 3.5em;
  }
}

@media screen and (max-width: 768px) {

  .sectionContent {
    width: 100%;
    text-align: center;
    padding : 0 !important;
  }
  
  .sectionContainer{
    overflow-x: clip;
  }

  .SectionTexts{
    padding: 0 1em;
  }
  .contentImg{
    position: static;
    z-index: 1;
    margin: 3.5em 0 0 0;
  }
  .contentImg img {
    position: static;
    width: 70%;
    height: 70%;
    transform: scale(1.75);
    margin-left: 4em;
    margin-top: 5rem;
  }
}
