.timeline{
    background-color: rgba(0, 0, 0, 0.30);
}
.timeline ul {
  padding: 50px 0;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 2px;
  margin: 0 auto;
  padding-top: 30px;
  background: #fff;
}

.timeline ul::before,
.timeline ul::after {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #fff;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.timeline ul li:first-child {
  padding-top: 60px;
}

.timeline ul li:last-child {
  padding-bottom: 60px;
}

.timeline ul li:first-child::after {
  top: calc(50% + 60px / 4) !important;
}
.timeline ul li:last-child::after {
  top: calc(50% - 60px / 4) !important;
}

.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  top: calc(50% + 30px / 4);
  transform: translateX(-50%);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #9801e6;
  z-index: 1;
}

.timeline ul li div {
  position: relative;
  top: 0;
  width: 650px;
  background: rgba(60, 60, 71, 0.32);
  border-radius: 14px;
  color: #fff;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.timeline ul li:nth-child(odd) div {
  left: -135px;
}

.timeline ul li:nth-child(even) div {
  left: calc(-1 * (650px - 135px));
  flex-direction: row-reverse;
  text-align: right;
}

.timeline ul li:nth-child(odd) div time {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
  background: #3c3c4b;
  border-bottom-left-radius: 14px;
  border-top-left-radius: 14px;
  margin: 0;
  padding: 0 25px;
  min-width: 120px;
}

.timeline ul li:nth-child(even) div time {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
  background: #3c3c4b;
  border-bottom-right-radius: 14px;
  border-top-right-radius: 14px;
  margin: 0;
  padding: 0 25px;
  min-width: 120px;
}

.timeline ul li div time {
  flex-direction: column;
}

.timeline ul li div time span {
  font-size: 0.65em;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #a700f5;
}

.timeline ul li:nth-child(odd) div text {
  padding: 15px 15px 15px 35px;
}

.timeline ul li:nth-child(even) div text {
  padding: 15px 35px 15px 15px;
}

.timeline ul li div text span p{
  margin: 0;
}
.timeline ul li div text span{
  display: block;
  margin: 3px 0;
}

.timeline ul li:nth-child(odd) div text:not(.noCheck) span:not(.noCheck)::before{
  content: "\2713";
  color: #00E1F5;
  font-size: 1.2em;
}

.timeline ul li:nth-child(even) div text:not(.noCheck) span:not(.noCheck)::after{
  content: "\2713";
  color: #00E1F5;
  font-size: 1.2em;
}

.timeline ul li:nth-child(odd) div text.noCheck span::before,
.timeline ul li:nth-child(odd) div text span.noCheck::before
{
  content: "\26A0";
  color: var(--secondaryColor);
  font-size: 1.2em;
}

.timeline ul li:nth-child(even) div text.noCheck span::after,
.timeline ul li:nth-child(even) div text span.noCheck::after{
  content: "\26A0";
  color: var(--secondaryColor);
  font-size: 1.2em;
}


.timeline ul li div text h4{
  margin-top: 10px;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 8px;
}

.timeline ul li:nth-child(odd) div text h4::after{
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  background: rgba(255, 255, 255, 0.2);
  width: 50%;
  height: 2px;
}

.timeline ul li:nth-child(even) div text h4::after{
  content: "";
  position: absolute;
  right: 0;
  bottom: -3px;
  background: rgba(255, 255, 255, 0.2);
  width: 50%;
  height: 2px;
}


/* GENERAL MEDIA QUERIES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 1060px) {
  .timeline ul li div {
    width: 500px;
  }
  .timeline ul li:nth-child(even) div {
    left: calc(-1 * (500px - 135px));
  }
}

@media screen and (max-width: 720px) {
  .timeline ul::before,
  .timeline ul::after {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #fff;
    position: absolute;
    border-radius: 50%;
    left: 106px;
    transform: translateX(-50%);
    z-index: 5;
  }

  .timeline ul li {
    margin: 0 0 0 105px;
  }

  .timeline ul li div {
    width: calc(100vw - 10px);
  }

  .timeline ul li:nth-child(odd) div {
    left: -100px;
  }

  .timeline ul li:nth-child(odd) div time,
  .timeline ul li:nth-child(even) div time {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.95rem;
    font-weight: bold;
    margin: 0;
    padding: 0 5px !important;
    min-width: 85px;
    max-width: 95px;
    border-bottom-left-radius: 17px;
    border-top-left-radius: 17px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .timeline ul li:nth-child(odd) div text,
  .timeline ul li:nth-child(even) div text {
    padding: 15px 10px 10px 25px;
    font-size: 0.85em;
  }

  .timeline ul li:nth-child(even) div {
    left: -100px;
    flex-direction: row;
    text-align: left;
  }
}

/* EFFECTS
  –––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul li::after {
  transition: background 0.5s ease-in-out;
}

.timeline ul li.in-view::after {
  background: #9801e6;
}

.timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

/* EXTRA/CLIP PATH STYLES
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline-clippy ul li::after {
  width: 40px;
  height: 40px;
  border-radius: 0;
}

.timeline-rhombus ul li::after {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.timeline-rhombus ul li div::before {
  bottom: 12px;
}

.timeline-star ul li::after {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.timeline-heptagon ul li::after {
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
}

.timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}
