.content {
  margin: 50px 0;
  padding: 100px 0;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentTitle h2 {
  font-weight: bold;
  font-size: 30px;
  line-height: 39px;
  color: #ffffff;
  position: relative;
  display: inline-block;
}

.contentTitle h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #fff;
}

.contentTexts {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentTexts h3 {
  font-weight: bold;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 0.5px;
  color: #ffffff;
  text-align: center;
  margin-top: 25px;
}

.contentTexts p {
  font-size: 17px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  width: 34%;
}

.contentBtn {
  margin-top: 25px;
}

.contentBtn a {
  padding: 7px 25px;
  background: var(--graidentColor);
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-decoration: none;
}

.contentBtn a:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.4),
    0 0 40px rgba(255, 255, 255, 0.5);
    color: #fff;
}

@media screen and (max-width: 1200px) {
  .content {
    background-position-x: center !important;
    margin: 0 0 50px 0 !important;
  }

  .contentTexts {
    width: 70%;
    padding: 0 10px;
  }

  .contentTitle h2 {
    font-size: 26px;
    line-height: 28px;
  }

  .contentTexts h3 {
    font-size: 26px;
    line-height: 38px;
    letter-spacing: 0.5px;
  }

  .contentTexts p {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .contentTexts {
    width: 100%;
    padding: 0 10px;
  }
}
