.content {
  height: calc(100vh - var(--navHeigh) - 580px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

.contentTitle h2 {
  font-size: 4em;
  font-weight: 600;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 9px;
  padding: 0.2em;
}

.contentDesc h4 {
  color: #fff;
  margin-bottom: 1em;
}

.contentDesc p {
  color: #fff;
  line-height: 0.75rem;
}

@media screen and (max-width: 600px) {
  .contentTitle h2 {
    font-size: 2.5em;
    font-weight: 600;
  }
  .contentDesc p {
    color: #fff;
    margin-bottom: 0.4em;
    font-size: 0.75em;
    line-height: 1rem;
  }

  .contentDesc h4 {
    color: #fff;
    font-size: 1em;
  }
}
